import React, { useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import queryString from "query-string"
import { Button } from "components/shared/atoms/Button"
import { Logo } from "components/shared/Logo"
import SEO from "components/shared/seo"

const connect = (props: any) => {
  const parsed = queryString.parse(props.location.search)

  useEffect(() => {
    if (!parsed.eventId) {
      navigate("/")
    }
  }, [])

  return (
    <Container>
      <SEO
        title="OneID | Se connecter"
        description="Se connecter à un évènement OneID"
      />
      <ContentContainer>
        <LogoContainer>
          <Logo width="150" link="/" />
        </LogoContainer>
        <Sub>
          Vous avez été invité à vous connecter à un évènement OneID Pro
        </Sub>
        <Button link={`oneidpro://app/connect/${parsed.eventId}`}>
          Se connecter
        </Button>
        <SubSmall>
          {`Code évènement : `}
          <h3>{parsed.eventId}</h3>
        </SubSmall>
      </ContentContainer>
    </Container>
  )
}

export default connect

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.gray};
`

const ContentContainer = styled.div`
  max-width: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Sub = styled.h2`
  margin: ${props => props.theme.margins.m6} 0;
  text-align: center;
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes.lg};
  color: ${props => props.theme.colors.secondarydark};
`

const SubSmall = styled(Sub)`
  font-size: ${props => props.theme.fontSizes.sm};
`
